import axios from "axios";

const BASE_URL = process.env.REACT_APP_HOST_API;

const addProject = async (token,data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };   
  const response = await axios.post(
    `${BASE_URL}/admin/project/add`,
    data,
    config
  );
  if (response.data) {
    return response.data;
  } 
  throw new Error("Add Project api failed"); 
};

const userProject = async (token,pageno,limit,search) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };     
    const response = await axios.get(
      `${BASE_URL}/admin/user/project?page=${pageno}&perPage=${limit}&search=${search??''}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Project List api failed"); 
  };

  const updateProject = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.put(
      `${BASE_URL}/admin/project/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Updated Project api failed"); 
  };

  const activeProject = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/user/project/active`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Project api failed"); 
  };


  const allProject = async (token,pageno,limit) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };   
    const response = await axios.get(
      `${BASE_URL}/superAdmin/project/all?page=${pageno}&perPage=${limit}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Project List api failed"); 
  };

  const singleProject = async (token,id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.get(
      `${BASE_URL}/admin/project/${id}`,
      config
    );
    if (response.data) {
      console.log('singleProject===========',  response.data);
      return response.data;
    } 
    throw new Error("Get Single Project api failed"); 
  };

  const assignProject = async (token,id,data) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };       
    const response = await axios.put(
      `${BASE_URL}/admin/assign/project/${id}`,
      data,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Assign Project api failed"); 
  };

  const getBusinessProject = async (token,business) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };  
    const response = await axios.get(
      `${BASE_URL}/admin/business/project/${business}`,
      config
    );
    if (response.data) {
      return response.data;
    } 
    throw new Error("Get Active Business Project api failed"); 
  };
  
  

const projrctServices = {
    addProject,
    userProject,
    updateProject,
    activeProject,
    allProject,
    singleProject,
    assignProject,
    getBusinessProject
};

export default projrctServices;
